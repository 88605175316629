(function() {
  "use strict";

  function ImageHelper() {
    var __PDF_DOC,
      __TOTAL_PAGES,
      __SCALE = 1.5;

    if (typeof pdfjsLib !== "undefined") {
      pdfjsLib.GlobalWorkerOptions.workerSrc = "/lp-ext/pdf.worker.4.6.82.js";
    }

    async function getAllImages(pdf_url) {
      const images = [];

      try {
        if (pdf_url.url?.startsWith('/')) {
          pdf_url = window.location.origin + pdf_url;
        }

        const pdf_doc = await pdfjsLib.getDocument(pdf_url).promise;
        __PDF_DOC = pdf_doc;
        __TOTAL_PAGES = __PDF_DOC.numPages;

        for (let pageIndex = 1; pageIndex <= __TOTAL_PAGES; pageIndex++) {
          images.push(await getImageDataURL(pageIndex));
        }
      } catch (error) {
        console.log(error);
      } finally {
        // Remove temporary canvas from the DOM
        document.querySelectorAll(".pdf-canvas").forEach(canvas => {
          canvas.remove();
        });

        return images;
      }
    }

    async function getImageDataURL(page_no) {
      // Fetch the page
      const page = await __PDF_DOC.getPage(page_no);
      const viewport = page.getViewport({ scale: __SCALE });
      const canvas = document.createElement("canvas");
      document.body.appendChild(canvas);

      canvas.id = "pdf-canvas-" + page_no;
      canvas.classList.add("pdf-canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const context = canvas.getContext("2d");

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };

      // Render the page contents in the canvas
      await page.render(renderContext).promise;

      const src = canvas.toDataURL("image/png");
      return src;
    }

    this.getImage = async function(file) {
      const images = await getAllImages({ url: file });
      return images;
    };

    this.getImagesFromPDF = function(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async function() {
          try {
            const arrayBuffer = this.result;
            const images = await getAllImages({ data: arrayBuffer });
            resolve(images);
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    };

    this.getImagesFromFile = async function(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = function() {
          resolve([reader.result]);
        };

        reader.readAsDataURL(file);
      });
    };

    this.resizeImage = async function(image, maxWidth = 1632, maxHeight = 2112) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function() {
          let width = img.width;
          let height = img.height;

          if (width <= maxWidth || height <= maxHeight) {
            resolve(img.src);
            return;
          }

          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/png"));
        };

        img.src = image;
      });
    }
  }

  window.ImageHelper = new ImageHelper();
})();
